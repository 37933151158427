.contact{
  width: 100%;
  height: auto;
  background-color: var(--primary-color-bg);
  color: var(--primary-color-text);
  font-family: 'Lora', serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contact_content{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_content h2{
  font-size: 4em;
}

.contact_content p{
  font-size: 2em;
  width: 70%;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.contact_content-email{
  text-align: center;
  font-size: 1em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

#email{
  width: auto;
}

.contact_content h3{
  font-size: 3em;
}

@media(max-width: 1280px){
  .contact_content h2{
    font-size: 3em;
  }

  .contact_content p{
    width: 80%;
  }
}

@media(max-width: 769px){
  .contact_content h2{
    font-size: 2.5em;
  }
  
  .contact_content p{
    text-align: center;
    font-size: 2em;
    width: 80%;
  }
  
  .contact_content h3{
    font-size: 2.5em;
  }
}

@media(max-width: 481px){
  .contact_content h2{
    font-size: 2em;
  }

  .contact_content p{
    font-size: 1.2em;
    width: 80%;
  }

  .contact_content h3{
    font-size: 2em;
  }
}