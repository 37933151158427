.home{
  width: 100%;
  height: auto;
  background-color: var(--primary-color-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.home .intro{
  width: 65%;
  height: auto;
  color: var(--secondary-color-text);
  display: flex;
  justify-content: flex-start;
}

.intro .intro-container{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Lora', serif;
}

.intro-container .intro-container_title{
  font-size: 4rem;
  line-height: 1.25;
  letter-spacing: 1px;
  margin: 0;
}

.intro-container .intro-container_location{
  font-size: 2rem;
  font-weight: 700;
}

.intro-container .intro-container_info{
  font-size: 2rem;
  line-height: 1.5;
}

.home .image{
  background-color: var(--secondary-color-bg);
  width: 35%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.image .image-container{
  width: 100%;
  height: 60%;
  display: flex;
}

.image .image-container img{
  width: 100%;
  object-fit: cover;
}

@media(max-width: 1280px){
  .intro .intro-container{
    width: 500px;
  }

  .intro-container .intro-container_title{
    font-size: 3rem;
    line-height: 1.20;
    letter-spacing: .8px;
  }
  
  .intro-container .intro-container_location{
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .intro-container .intro-container_info{
    font-size: 1.8rem;
    line-height: 1.3;
  }
}

@media(max-width: 769px){
  .home{
    flex-direction: column;
    justify-content: flex-start;
  }

  .home .intro{
    width: 100%;
    height: auto;
    margin-top: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .home .image{
    width: 100%;
    height: 380px;
    margin-top: 30px;
    margin-bottom: 100px;
    order: 5;
    background-color: transparent;
  }

  .image .image-container{
    width: 100%;
    height: 100%;
    margin-left: 0;
    display: flex;
    background-color: var(--secondary-color-bg);
  }
  
  .image .image-container img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .intro .intro-container{
    width: 80%;
  }

  .intro-container .intro-container_title{
    font-size: 3rem;
    line-height: 1;
    letter-spacing: .6px;
  }
  
  .intro-container .intro-container_location{
    font-size: 1.7rem;
    font-weight: 700;
  }
  
  .intro-container .intro-container_info{
    font-size: 2rem;
    line-height: 1.1;
  }
}

@media(max-width: 481px){
  .home{
    justify-content: flex-start;
  }

  .home .intro{
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .intro .intro-container{
    width: 400px;
    margin-left: 0;
    text-align: center;
  }

  .intro-container .intro-container_title{
    font-size: 2rem;
    line-height: 1;
    letter-spacing: .6px;
  }
  
  .intro-container .intro-container_location{
    font-size: 1.2rem;
    font-weight: 700;
  }
  
  .intro-container .intro-container_info{
    font-size: 1.3rem;
    line-height: 1;
  }

  .home .image{
    width: 100%;
    height: 310px;
    order: 5;
    background-color: transparent;
  }
  
  .image .image-container{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--secondary-color-bg);
  }
  
  .image .image-container img{
    width: 100%;
  }
}