:root{
  --primary-color-bg: #CBE4DE;
  --secondary-color-bg: #313b57;
  --primary-color-text : #CBE4DE;
  --secondary-color-text: #313b57;
  --filter: invert(17%) sepia(9%) saturate(3003%) hue-rotate(187deg) brightness(98%) contrast(82%);
  --filter1: invert(91%) sepia(22%) saturate(140%) hue-rotate(115deg) brightness(95%) contrast(91%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
