.about{
  width: 100%;
  height: auto;
  background-color: var(--primary-color-bg);
  color: var(--primary-color-text);
  font-family: 'Lora', serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.about .about_title{
  margin-top: 100px;
}

.about .about_title h2{
  font-size: 4em;
}

.about .about_info{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.about .about_info p{
  font-size: 2em;
}

@media(max-width: 1280px){
  .about .about_title h2{
    font-size: 3em;
  }

  .about .about_info p{
    font-size: 2em;
    width: 100%;
  }
}

@media(max-width: 769px){
  .about{
    padding: 10px;
  }

  .about .about_title h2{
    font-size: 2.5em;
  }
  
  .about .about_info p{
    font-size: 2em;
    width: 100%;
  }
}

@media(max-width: 481px){
  .about .about_title h2{
    font-size: 2em;
  }

  .about .about_info{
    width: 100%;
    text-align: center;
  } 
  
  .about .about_info p{
    font-size: 1.2em;
   }  
}