.projectsContainer{
  height: auto;
  width: 100%;
  font-family: 'Lora', serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  background-color: var(--primary-color-bg);
  color: var(--primary-color-text);
}

.projectsContainer .title{
  text-align: center;
  margin-top: 100px;
}

.projectsContainer .title h2{
  font-size: 4em;
}

.projectsContainer .container{
  display: flex;
  flex-direction: column;
  
  align-items: center;
  padding: 20px;
}

@media(max-width: 1280px){
  .projectsContainer{
    height: auto;
  }
  .projectsContainer .title h2{
    font-size: 3em;
  }
}

@media(max-width: 769px){
  .projectsContainer .title h2{
    font-size: 2.5em;
  }
}

@media(max-width: 481px){
  .projectsContainer .title h2{
    font-size: 2em;
  }
}