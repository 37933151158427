.footer{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: transparent;
}

.footer_info{
  font-family: 'Lora', serif;
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--secondary-color-bg);
}

.footer_info-message{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer_info-message h2{
  font-size: 2em;
  margin-left: 50px;
  margin-bottom: 0;
  color: var(--primary-color-text);
}

.footer_info-message p{
  color: var(--primary-color-text);
  font-size: 30px;
  margin-left: 50px;
}

.hiddenP{
  display: none;
}

.footer_info-message p span a{
  color: var(--primary-color-text);
  border-bottom: 1px solid var(--primary-color-text);
  cursor: pointer;
}

.footer_socialMedia{
  width: 35%;
  background-color: var(--primary-color-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_socialMedia-title{
  text-align: center;
  font-family: 'Lora', serif;
  color: var(--secondary-color-text);
}

.footer_socialMedia-title p{
  font-size: 2em;
  font-weight: bold;
}

.footer_socialMedia-icons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon{
  height: 50px;
  margin: 5px;
  cursor: pointer;
  color: var(--secondary-color-text);
}



.filter{
  filter: var(--filter);
}

@media(max-width: 769px){
  .footer_info-message h2{
    font-size: 35px;
    margin-left: 30px;
    color: var(--primary-color-text);
  }

  .footer_info-message p{
    font-size: 25px;
    margin-left: 30px;
  }

  

  .icon{
    height: 40px;
    margin: 10px;
    color: var(--secondary-color);
  }
}

@media(max-width: 481px){
  .footer_info-message h2{
    font-size: 20px;
    margin: 10px;
    color: var(--primary-color-text);
  }

  .footer_info-message p{
    font-size: 20px;
    margin: 10px;
  }

  .footer_socialMedia-title{
    color: var(--primary-color-text);
  }

  .footer_socialMedia-title p{
    font-size: 1.1em;
    margin: 10px;
  }

  .icon{
    height: 25px;
    margin: 3px;
    color: var(--secondary-color);
  }

  .footer_socialMedia{
    background-color: var(--secondary-color-bg);
  }

  .filter{
    filter:var(--filter1);
  }
}