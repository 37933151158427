#header{
    display: flex;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 10;
}

#header .brand{
    width: 65%;
    text-align: center;
    align-self: center;
    position: relative;
}

/*#header .brand a{
    font-weight: normal;
    font-family: 'Noto Serif JP', serif;
    font-size: 2em;
    letter-spacing: 1px;
    color: var(--secondary-color-text);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}*/

#header .logo{
    cursor: pointer;
    height: 50px;
    margin-right: 500px;
    width: 50px;
    color: var(--secondary-color-text);
}

#header .filter{
    filter: var(--filter);
  }

#header .navbar{
    width: 35%;
    display: flex;
    margin-left: auto;
}

#header .navbar_list{
    display: flex;
    align-self: center;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
}

#header .navbar_list li{
    font-size: 1.3em;
    list-style: none;
    margin: 5px;
    letter-spacing: 1px;
    color: var(--primary-color-text);
    padding: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}

#header .translate{
    position: absolute;
    left: 50%;
    top: 25%;
}

.translate .translate_icon{
    height: 25px;
    color: var(--secondary-color-text);
    cursor: pointer;
}

.navbar .toggle-button{
    position: absolute;
    top: 2rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
}

.navbar .toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: var(--primary-color-text);
    border-radius: 10px;
}

@media(max-width: 1280px){
    #header .brand{
        width: 65%;
    }

    #header .navbar{
        width: 35%;
    }

    #header .navbar_list li{
        font-size: 1em;
        margin: 0;
        letter-spacing: .5px;
        padding: 10px;
    }

    #header .navbar .navbar_btn-container{
        width: 50%;
        justify-content: flex-end;
    }

    #header .navbar .navbar_btn-language{
        font-size: 1em;
    }
}

@media(max-width: 769px){
    #header{
        background-color: var(--secondary-color-bg)
    }

    #header .brand{
        width: 30%;
    }

    #header .filter{
        filter: var(--filter1);
      }

    #header .navbar{
        width: 70%;
    }

    /*#header .brand a{
        font-size: 1.5em;
        color: var(--primary-color-text)
    }*/

    #header .navbar_list li{
        color: var(--primary-color-text);
        font-size: 1em;
        padding: 5px;
    }

    #header .translate{
        position: absolute;
        left: 90%;
        top: 27%;
    }

    .translate .translate_icon{
        color: var(--primary-color-text);
    }
}

@media(max-width: 481px){
    #header{
        flex-direction: column;
        background-color: var(--secondary-color-bg);
    }
    
    #header .brand{
        width: 100%;
        display: flex;
    }

    #header .filter{
        filter: var(--filter1);
      }

    /*#header .brand a{
        font-size: 1.5em;
        margin-top: 0;
        margin-left: .5rem;
        padding: .5rem;
        margin-right: auto;
    }*/

    #header .navbar{
        width: 100%;
        flex-direction: column;
    }

    .navbar .toggle-button{
        top: .5rem;
        padding: .5rem;
        display: flex;
    }

    #header .navbar_list{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #header .navbar_list li{
        font-size: 1.2em;
        margin: 5px;
    }

    #header .translate{
        left: 47%;
    }

    .translate .translate_icon{
        color: var(--primary-color-text)
    }

    #header .translate.active{
        top: 7%;
    }
    
    #header .navbar_list.active{
        display: flex;
        
    }

    #header .navbar .navbar_btn-container.active{
        display: flex;
    }
}